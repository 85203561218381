export const UPDATE_TYPE = 1;
export const CREATE_TYPE = 2;
export const DELETE_TYPE = 3;
export const ERROR_TYPE = 4;
export const LONGITUD_MAXIMA_TELEFONOS = 12;
export const LONGITUD_MINIMA_TELEFONOS = 7;
export const LONGITUD_MAXIMA_DOCUMENTOS_PERSONA_NATURAL = 10;
export const LONGITUD_MAXIMA_DOCUMENTOS_PERSONA_JURIDICA = 13;
export const VALIDACION_REGEX_TELEFONOS = '^[+]?[0-9]*$';
export const VALIDACION_REGEX_DOCUMENTOS = '^[A-Z]*?[0-9]*$';
export const VALIDACION_REGEX_NUMEROS = '^[0-9]*$';